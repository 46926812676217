<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_assets') }}</h1>
      </b-row>
      <account-plan-submenu active="assets"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-assets-header></account-plan-assets-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs"  @activate-tab="onTabActivate">
            <AssetsMenu>
              <template slot='account-plan-assets-four-buckets'>
                <b-row class="px-3">
                  <b-col>
                    <b-row no-gutters>
                      <b-col cols="10">
                        <div class="text-regular" v-html="$t(translationPath + 'asset.four_bucket.description')"></div>
                      </b-col>
                    </b-row>

                    <b-table class="spirecta-simple-table account-plan-table income-accounts-table" show-empty hover responsive striped
                      stacked="md"
                      :items="accountsGrouped.asset"
                      :fields="fieldValues"
                      :tbodyTrClass="trClass"
                      :busy="busy"
                    >
                      <template v-slot:table-busy>
                        <loader/>
                      </template>
                      <template v-slot:cell(title)="row">
                        <template v-if="!row.item.is_account">
                          <b-link :to="'/reports/performance/account-groups/' + row.item.id">
                            {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                          </b-link>
                        </template>
                        <template v-else>
                          <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'"><span class="px-2">–</span>
                            {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                          </b-link>
                        </template>
                      </template>

                      <template v-slot:cell(bucket)="row">
                        <template v-if="row.item.is_account">
                          <b-form-select
                            :disabled="isFormDisabled"
                            :options="selectBucketOptions"
                            v-model="row.item.in_bucket"
                            @input="onChange"
                          ></b-form-select>
                        </template>
                        <template v-else-if="!row.item.is_account && row.item.type === 'asset'">
                          <div class="tr_heading">{{ $t('assets_liabilities.create_account_wizard.assets.step3.in_bucket_label') }}</div>
                        </template>
                      </template>

                      <template v-slot:cell(column3)="row">
                        <template v-if="row.item.is_account && row.item.in_bucket">
                          <b-form-select
                            :disabled="isFormDisabled"
                            v-model="row.item.is_bucket_free_capital"
                            :options="freeTiedCapitalOptions"
                            @input="onChange"
                          ></b-form-select>
                        </template>
                        <template v-else-if="!row.item.is_account && row.item.type === 'asset'">
                          <div class="tr_heading">{{ $t('assets_liabilities.create_account_wizard.assets.step3.is_bucket_free_capital') }}</div>
                        </template>
                        <template v-else>&nbsp;</template>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row class="mb-5 mt-4">
                  <b-col class="pt-4 pt-md-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right btn-save ml-3"
                      :disabled="saveDisabled"
                      @click="onSave"
                    >{{ $t('common.save') }}</b-button>
                  </b-col>
                </b-row>
              </template>
              </AssetsMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->
    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AssetsMenu from './submenu/AssetsMenu'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'

export default {
  name: 'AccountPlanAssetsFourBuckets',
  mixins: [AccountPlanTabMixin],
  components: { AssetsMenu, AccountPlanAssetsHeader },
  data () {
    return {
      accountsFilter: ''
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title') },
        { key: 'bucket', label: '' },
        { key: 'column3', label: '' }
      ]
    },
    selectBucketOptions () {
      return [
        { value: null, text: '' },
        { value: 'conservative', text: this.$t('reports.balance.four_buckets_report.submenu.conservative') },
        { value: 'moderate', text: this.$t('reports.balance.four_buckets_report.submenu.moderate') },
        { value: 'aggressive', text: this.$t('reports.balance.four_buckets_report.submenu.aggressive') },
        { value: 'speculation', text: this.$t('reports.balance.four_buckets_report.submenu.speculation') }
      ]
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData('asset'),
        this.loadAccountGroups('asset')
      ])
        .then(() => {
          this.busy = false
        })
    },
    onSave () {
      this.saveDisabled = true

      const accounts = this.accountsGrouped.asset.filter((item) => item.is_account ? item : null)
      const putData = {
        accounts: []
      }
      accounts.map((item) => {
        putData.accounts.push({ id: item.id, in_bucket: item.in_bucket, is_bucket_free_capital: item.is_bucket_free_capital })
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
        .then(() => {
          this.$bvToast.toast(this.$t('reports.balance.four_buckets_report.toast.success_update_buckets').toString(), {
            title: this.$t('reports.balance.four_buckets_report.toast.heading').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.hasUnsavedChanges = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t('reports.balance.four_buckets_report.toast.error_update_buckets').toString(), {
            title: this.$t('reports.balance.four_buckets_report.toast.heading').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
